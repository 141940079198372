import Util from "../utils/util.js";
import Glide from '@glidejs/glide';
import lightGallery from 'lightgallery';

// Plugins
import lgZoom from 'lightgallery/plugins/zoom'
class ParagraphBannerSlider {
    constructor() {
        this.initParagraphBannerSlider();
    }
    initParagraphBannerSlider(){
        let paragraphBannerSlider = Util.$('.js-paragraph-banner-slider');
        if(paragraphBannerSlider.length > 0){
            paragraphBannerSlider.forEach(el => {
                let slider = Util.$('.js-paragraph-banner-slider__media-slider', el);
                if(slider.length > 0){
                    lightGallery(slider[0], {
                        plugins: [lgZoom],
                        licenseKey: 'C94E7EDA-1939-4C9D-A24B-8E652BE5EFBD',
                        speed: 500,
                        download: false,
                        selector: '.js-paragraph-banner-slider__lightbox-link'
                    });
                    let thisSlider = new Glide(slider[0], {
                        rewind : false,
                        animationDuration : 350,
                        animationTimingFunc: 'ease-out'
                    });
                    let ctrls = Util.$('.js-paragraph-banner-slider__bullets', el);
                    let slides = Util.$('.js-glide__slide', el);
                    let index = 0;
                    let lightboxLink = Util.$('.js-paragraph-banner-slider__lightbox-toggle', el)[0];
                    lightboxLink.addEventListener('click', function(ev){
                        ev.preventDefault();
                        ev.stopPropagation();
                        let currentSlide = Util.$('.js-glide__slide.glide__slide--active', el)[0];
                        let currentSlideLink = Util.$('.js-paragraph-banner-slider__lightbox-link', currentSlide)[0];
                        currentSlideLink.click();
                    });
                    slides.forEach(el => {
                        let bullet = document.createElement('button');
                        bullet.setAttribute('data-glide-dir', "=" + index );
                        bullet.setAttribute('class', 'glide__bullet' );
                        ctrls[0].append(bullet);
                        index = index + 1;
                    });
                    thisSlider.mount();
                }
            });
        }
        
    }
}
export default ParagraphBannerSlider;
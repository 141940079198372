import { gsap } from "gsap/gsap-core";
import { CSSPlugin } from 'gsap/CSSPlugin';
import Util from "../utils/util.js";
import * as fecha from "fecha";
import HotelDatepicker from "hotel-datepicker";

gsap.registerPlugin(CSSPlugin);
class Booking {
    constructor() {
        this.initBooking();
    }
    settings = {
        desktop: '1056px',
        toggleClass: '-item-active'
    }
    mobileCheck(){
        if(window.matchMedia('(min-width:'+ this.settings.desktop +')').matches){
            return false;
        }else{
            return true;
        }
    }
    initBooking(){
        let bookingbar = Util.$('.js-booking');
        let lpb = this;
        
        
        
        if(bookingbar.length > 0){
            //lpb.bindBookingBarToggle(el, toggle, content);
            bookingbar.forEach(function(el){
                let thisBar = el;
                let formGroups = Util.$('.js-booking__input-ctrl-group', thisBar);
                let submit = Util.$('.js-booking__input-submit-btn', thisBar)[0];
                let mobileToggle = Util.$('.js-booking__mobile-toggle-btn', thisBar)[0];
                let mobileToggleDropdown = Util.$('.js-booking__bar',thisBar)[0];
                let mobileToggleDropdownContent = Util.$('.js-booking__bar-wrapper',thisBar)[0];
                const labels = Util.$('.js-booking__input-date',thisBar);
                labels.forEach(function(el){
                    const thisSvg = el.nextElementSibling;
                    thisSvg.addEventListener('click', function(e) {
                        el.focus();
                    });
                });
                formGroups.forEach(function(el){
                    lpb.bindIncrements(el);
                });
                lpb.bindSearch(submit);
                lpb.bindMobileToggle(mobileToggle, mobileToggleDropdown, mobileToggleDropdownContent);
            });
        }

        const bookingToggle = document.querySelector('.js-booking__toggle');
        if (bookingToggle) {
            //Show primary dropdown
            bookingToggle.addEventListener('click', function(e) {
                e.preventDefault();
                const bookingToggleDropdown = document.querySelector('.js-booking__callout');
                const mobileToggle = document.querySelector('.js-booking__mobile-toggle-btn');
                if (bookingToggleDropdown.classList.contains('-open')) {
                    let tween = {height: '0px'};
                    if (window.matchMedia('(min-width: 1056px)').matches){
                        gsap.to(bookingToggleDropdown, tween);
                    }else{
                        mobileToggle.click();
                    }
                    bookingToggleDropdown.classList.remove('-open');
                    bookingToggle.parentElement.classList.remove('-active');
                } else {
                    let tween = {height: ''};
                    if (window.matchMedia('(min-width: 1056px)').matches){
                        tween.height = 340;
                        gsap.to(bookingToggleDropdown, tween);
                    }else{
                        tween.height = 60;
                        mobileToggle.click();
                    }
                        
                    
                    bookingToggleDropdown.classList.add('-open');
                    bookingToggle.parentElement.classList.add('-active');
                }
            });
        }
        

        const inputArrival = document.getElementById('arrival');
        const inputDeparture = document.getElementById('departure');

        if (inputArrival && inputDeparture) {
            
            const datePickerOptions = {
                topbarPosition: 'bottom',
                autoClose: false,
                clearButton: true,
                submitButton: true,
                submitButtonName: 'Submit',
                moveBothMonths: true
            };
            const inputArrivalDatePicker = new HotelDatepicker(inputArrival, datePickerOptions);
            const inputDepartureDatePicker = new HotelDatepicker(inputDeparture, datePickerOptions);
            const labelArrival = document.querySelector('.booking__mobile-date.-arrival');
            const labelDeparture = document.querySelector('.booking__mobile-date.-departure');

            inputArrival.addEventListener('afterClose', function () {
                let arrivalDate = inputArrivalDatePicker.start;
                let departureDate = inputArrivalDatePicker.end;
                if (arrivalDate && departureDate) {
                    arrivalDate = new Date(arrivalDate);
                    departureDate = new Date(departureDate);
                    const
                        arrivalDateVal = arrivalDate.toISOString().substring(0, 10),
                        arrivalDateTxt = arrivalDate.toLocaleDateString('en-us', { month:"short", day:"numeric"})
                    const
                        departureDateVal = departureDate.toISOString().substring(0, 10),
                        departureDateTxt = departureDate.toLocaleDateString('en-us', { month:"short", day:"numeric"});

                    inputArrival.dataset.value = arrivalDateVal;
                    inputDeparture.dataset.value = departureDateVal;
                    inputArrival.value = arrivalDateTxt;
                    inputDeparture.value = departureDateTxt;

                    if (labelArrival)
                        labelArrival.innerHTML = arrivalDateTxt;
                    if (labelDeparture)
                        labelDeparture.innerHTML = departureDateTxt;
                    
                    inputDepartureDatePicker.start = arrivalDate;
                    inputDepartureDatePicker.end = departureDate;
                } else {
                    Date.prototype.addDays = function(days) {
                        var date = new Date(this.valueOf());
                        date.setDate(date.getDate() + days);
                        return date;
                    }
                    const today = new Date();
                    const tomorrow = today.addDays(1);
                    const firstDay = tomorrow.toLocaleDateString('en-us', { month:"short", day:"numeric"});
                    const twoDays = tomorrow.addDays(2);
                    const lastDay = twoDays.toLocaleDateString('en-us', { month:"short", day:"numeric"});
                    inputArrival.value = firstDay;
                    inputDeparture.value = lastDay;
                }
            }, false);

            inputDeparture.addEventListener('afterClose', function () {
                const arrivalDate = new Date(inputDepartureDatePicker.start);
                const departureDate = new Date(inputDepartureDatePicker.end);
                if (arrivalDate && departureDate) {
                    const testDate = arrivalDate.toLocaleDateString('en-us', { month:"short", day:"numeric"});
                    let
                        arrivalDateVal,
                        arrivalDateTxt;
                    let
                        departureDateVal,
                        departureDateTxt;
                    // inputArrival.value = arrivalDateVal;
                    // inputDeparture.value = departureDateVal;
                    // inputArrivalDatePicker.start = arrivalDate;
                    // inputArrivalDatePicker.end = departureDate;
                    
                    // FIXING DATE PICKER BUG
                    // TODO - Clean up this code
                    if(testDate === "Dec 31" || testDate === "Jan 1"){
                        const today = new Date();
                        Date.prototype.addDays = function(days) {
                            var date = new Date(this.valueOf());
                            date.setDate(date.getDate() + days);
                            return date;
                        }
                        const tomorrow = today.addDays(1);
                        const twoDays = tomorrow.addDays(2);
                        arrivalDateVal = tomorrow.toISOString().substring(0, 10);
                        arrivalDateTxt = tomorrow.toLocaleDateString('en-us', { month:"short", day:"numeric"});
                        departureDateVal = twoDays.toISOString().substring(0, 10);
                        departureDateTxt = twoDays.toLocaleDateString('en-us', { month:"short", day:"numeric"});

                    }else{
                        arrivalDateVal = arrivalDate.toISOString().substring(0, 10);
                        arrivalDateTxt = arrivalDate.toLocaleDateString('en-us', { month:"short", day:"numeric"});
                        departureDateVal = departureDate.toISOString().substring(0, 10);
                        departureDateTxt = departureDate.toLocaleDateString('en-us', { month:"short", day:"numeric"});

                    }

                    inputArrival.dataset.value = arrivalDateVal;
                    inputDeparture.dataset.value = departureDateVal;
                    inputArrival.value = arrivalDateTxt;
                    inputDeparture.value = departureDateTxt;

                    if (labelArrival)
                        labelArrival.innerHTML = arrivalDateTxt;
                    if (labelDeparture)
                        labelDeparture.innerHTML = departureDateTxt;
                    
                    inputDepartureDatePicker.start = arrivalDate;
                    inputDepartureDatePicker.end = departureDate;
                } else {
                    Date.prototype.addDays = function(days) {
                        var date = new Date(this.valueOf());
                        date.setDate(date.getDate() + days);
                        return date;
                    }
                    const today = new Date();
                    const tomorrow = today.addDays(1);
                    const firstDay = tomorrow.toLocaleDateString('en-us', { month:"short", day:"numeric"});
                    const twoDays = tomorrow.addDays(2);
                    const lastDay = twoDays.toLocaleDateString('en-us', { month:"short", day:"numeric"});
                    inputArrival.value = firstDay;
                    inputDeparture.value = lastDay;
                }
            }, false);
        }
        
    }
    bindIncrements(formGroup){
        let formInput = Util.$('.js-booking__input', formGroup)[0];
        let formBtns = Util.$('.js-booking__input-btn', formGroup);
        formBtns.forEach(function(el){
            el.addEventListener('click', function(ev){
                if(el.classList.contains('-less')){
                    if(parseInt(formInput.value)> 0){
                        formInput.value = parseInt(formInput.value) - 1;
                    }
                }else{
                    formInput.value = parseInt(formInput.value) + 1;
                }
            });
        });
    }
    bindSearch(btn){
        btn.addEventListener('click', function(ev){
            ev.preventDefault();
            let url = "https://inntopia.travel/Ecomm/Shop/Lodging/5707625/en-US/";
            let queryString = "?";
            let arrival = document.getElementById("arrival").dataset.value;
            let departure = document.getElementById("departure").dataset.value;
            let adultCount = document.getElementById("adults").value;
            let childCount = document.getElementById("children").value;
            queryString = queryString + 'arrivalDate='+arrival+'&departureDate='+departure+'&adultcount='+adultCount+'&childcount='+childCount;
            url = url+queryString;
            window.open(url, "_blank");
        })
    }
    bindMobileToggle(toggle, dropdown, content){
        // FOR ALL MENU TOGGLE BUTTONS - TOGGLE CLASS ON ITEM AND DROPDOWN
        let h = this;
        
        toggle.addEventListener('click', function(ev){
            ev.preventDefault();
            if(toggle.classList.contains('-active')){
                h.hideDropdown(dropdown);
                toggle.classList.remove('-active');
            }else{
                let height = content.offsetHeight + 30;
                h.showDropdown(dropdown, height);
                toggle.classList.add('-active');
            }
        });
        
    }
    showDropdown(dropdown,height){
        let tween = {height: ''};
        tween.height = height;
        gsap.to(dropdown, tween);
    }
    hideDropdown(dropdown){
        let tween = {height: '0px'};
        gsap.to(dropdown, tween);
    }
}
export default Booking;
import { gsap } from "gsap/gsap-core";
import { CSSPlugin } from 'gsap/CSSPlugin';
import Util from "../utils/util.js";
gsap.registerPlugin(CSSPlugin);
class Amenities {
    constructor() {
        this.initAmenities();
    }
    settings = {
        desktop: '1056px',
        toggleClass: '-item-active'
    }
    mobileCheck(){
        if(window.matchMedia('(min-width:'+ this.settings.desktop +')').matches){
            return false;
        }else{
            return true;
        }
    }
    initAmenities(){
        let amenities = Util.$('.js-amenities');
        let a = this;
        amenities.forEach(function(el){
            let toggle = Util.$('.js-amenities__mobile-toggle', el)[0];
            let content = Util.$('.js-amenities__group-wrapper', el)[0];
            a.bindAmenitiesToggle(el, toggle, content);
        });
        //this.bindDropdownToggle(mainNav[0], menuButton[0], header[0], '-main-nav-open');
    }
    bindAmenitiesToggle(amenities, toggle, content){
        // FOR ALL MENU TOGGLE BUTTONS - TOGGLE CLASS ON ITEM AND DROPDOWN
        let h = this;
        
        
        toggle.addEventListener('click', function(ev){
            ev.preventDefault();
            toggle.classList.add('-hidden');
            amenities.classList.add('-show-all');
        });
    }
    showAmenities(accordion, container, height){
        let tween = {height: ''};
        tween.height = height;
        tween.onComplete = function(accordion){
            console.log('CALLBACK');
            console.log(accordion);
            accordion.classList.add('-open');
        };
        tween.onCompleteParams = [accordion];
        gsap.to(container, tween);
    }
    hideAmenities(accordion, container){
        let tween = {height: '0px'};
        tween.onComplete = function(accordion){
            console.log('CALLBACK');
            console.log(accordion);
            accordion.classList.remove('-open');
        };
        tween.onCompleteParams = [accordion];
        gsap.to(container, tween);
    }   
}
export default Amenities;